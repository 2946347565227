import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['other'];

  connect() {
    this.element.querySelectorAll('.radio-option').forEach((element) => {
      element.addEventListener('change', (event) => {
        if (event.target.value === 'Other') {
          this.otherTarget.classList.add('d-block');
          this.otherTarget.classList.remove('d-none');
        } else {
          this.otherTarget.classList.add('d-none');
          this.otherTarget.classList.remove('d-block');
        }
      })
    })
  }
}
