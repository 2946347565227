// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"
import NavbarController from "../controllers/navbar_controller";
import SearchController from "../controllers/search_controller";
import PostFormController from "../controllers/post_form_controller";
import CocController from "../controllers/coc_controller";
import RadiosController from "../controllers/radios_controller";

application.register("navbar", NavbarController)
application.register("search", SearchController)
application.register("post-form", PostFormController)
application.register("coc", CocController)
application.register("radios", RadiosController)

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
